import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { no, selectedLanguage, yes } from "../../../../../variable/global";
import { QuestionAnswer, Title } from "../../../../common/frequent/QuestionAnswer";
import {
	womenchild,
	birthCertificateQuestion,
	childVaccineQuestion,
	deliveryFromDoctorQuestion,
	pregnantNutritionQuestion,
	pregnanctCheckQuestion,
	childNutritionQuestion,
	childLabourQuestion,
	childLabor,
	earlyMarriageQuestion,
	pregnantTetanusQuestion,
} from "../../../../../variable/houseSurvey";

class SearchByHouseNumber extends Component {
	getYesNo = data => (data ? yes[selectedLanguage] : no[selectedLanguage]);
	render() {
		const { classes, womenChild } = this.props;
		return (
			<Grid container item direction="column" className={classes.container}>
				<Title title={womenchild} />
				<Grid item container direction="column">
					<Grid
						item
						container
						direction="row"
						className={classes.eachQnAns}
						spacing={24}
					>
						<Grid item xs={6}>
							<QuestionAnswer
								question={birthCertificateQuestion[selectedLanguage]}
								answer={this.getYesNo(womenChild.birthCertificateQuestion)}
							/>
						</Grid>
						<Grid item xs={6}>
							<QuestionAnswer
								question={childVaccineQuestion[selectedLanguage]}
								answer={this.getYesNo(womenChild.childVaccineQuestion)}
							/>
						</Grid>
					</Grid>
					<Grid item container direction="row" className={classes.eachQnAns}>
						<Grid item xs={6}>
							<QuestionAnswer
								question={deliveryFromDoctorQuestion[selectedLanguage]}
								answer={this.getYesNo(womenChild.deliveryFromDoctorQuestion)}
							/>
						</Grid>
						<Grid item xs={6}>
							<QuestionAnswer
								question={pregnantNutritionQuestion[selectedLanguage]}
								answer={this.getYesNo(womenChild.pregnantNutritionQuestion)}
							/>
						</Grid>
					</Grid>
					<Grid item container direction="row" className={classes.eachQnAns}>
						<Grid item xs={6}>
							<QuestionAnswer
								question={pregnanctCheckQuestion[selectedLanguage]}
								answer={this.getYesNo(womenChild.pregnanctCheckQuestion)}
							/>
						</Grid>
						<Grid item xs={6}>
							<QuestionAnswer
								question={childNutritionQuestion[selectedLanguage]}
								answer={this.getYesNo(womenChild.childNutritionQuestion)}
							/>
						</Grid>
					</Grid>
					<Grid item container direction="row" className={classes.eachQnAns}>
						<Grid item xs={6}>
							<QuestionAnswer
								question={childLabourQuestion[selectedLanguage]}
								answer={this.getYesNo(womenChild.childLabourQuestion)}
							/>
						</Grid>
						<Grid item xs={6}>
							<QuestionAnswer
								question={childLabor[selectedLanguage]}
								answer={this.getYesNo(womenChild.childLabor)}
							/>
						</Grid>
					</Grid>
					<Grid item container direction="row" className={classes.eachQnAns}>
						<Grid item xs={6}>
							<QuestionAnswer
								question={earlyMarriageQuestion[selectedLanguage]}
								answer={this.getYesNo(womenChild.earlyMarriageQuestion)}
							/>
						</Grid>
						<Grid item xs={6}>
							<QuestionAnswer
								question={pregnantTetanusQuestion[selectedLanguage]}
								answer={this.getYesNo(womenChild.pregnantTetanusQuestion)}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

const styles = theme => ({});

SearchByHouseNumber.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SearchByHouseNumber);
