import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
	animalInsurance,
	healthInsurance,
	insuranceListQuestion,
	insuranceLists,
	insuranceOther,
	insuranceQuestion,
	insuranceYesNo,
	lifeInsurance,
	nonlifeInsurance
} from "../../../../variable/houseSurvey";
import BoxBorder from "../../../common/frequent/BoxBorder";
import CheckBox from "../../../common/frequent/CheckBox";
import RadioButton from "../../../common/frequent/RadioButton";
import TextField from "../../../common/frequent/TextField";
class Insurance extends Component {
	handleChange = (name, value) => {
		this.props.handleChange(`insurance.${name}`, value);
	};
	render() {
		const { insurance } = this.props;
		const errors = this.props.errors && this.props.errors.errors;
		return (
			<Grid container direction="column">
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={insuranceQuestion}
						name="insuranceYesNo"
						radioLists={insuranceYesNo}
						value={insurance.insuranceYesNo}
					/>
				</Grid>
				<BoxBorder isVisible={insurance.insuranceYesNo == "insurance"}>
					<Grid container>
						<CheckBox
							error={errors && errors.insuranceLists}
							question={insuranceListQuestion}
							checkList={insuranceLists}
							value={insurance.insuranceLists}
							name="insuranceLists"
							isVisible
							handleCompChange={this.handleChange}
							other={insurance.insuranceOther}
						/>
					</Grid>
					<BoxBorder
						isVisible={
							insurance.insuranceLists &&
							insurance.insuranceLists.includes("life_insurance")
						}
					>
						<Grid container>
							<TextField
								error={errors && errors.lifeInsurance}
								question={lifeInsurance}
								name="lifeInsurance"
								type="number"
								placeholder="जीवन बीमा (संख्यामा)"
								value={insurance.lifeInsurance}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							insurance.insuranceLists &&
							insurance.insuranceLists.includes("health_insuran")
						}
					>
						<Grid container>
							<TextField
								question={healthInsurance}
								error={errors && errors.healthInsurance}
								name="healthInsurance"
								type="number"
								placeholder="स्वास्थ बीमा (संख्यामा)"
								value={insurance.healthInsurance}
								isVisible={
									insurance.insuranceLists &&
									insurance.insuranceLists.includes("health_insuran")
								}
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							insurance.insuranceLists &&
							insurance.insuranceLists.includes("non_life_insur")
						}
					>
						<Grid container>
							<TextField
								error={errors && errors.nonlifeInsurance}
								question={nonlifeInsurance}
								name="nonlifeInsurance"
								type="number"
								placeholder="नीर जीवन बीमा (संख्यामा)"
								value={insurance.nonlifeInsurance}
								isVisible={
									insurance.insuranceLists &&
									insurance.insuranceLists.includes("non_life_insur")
								}
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							insurance.insuranceLists &&
							insurance.insuranceLists.includes("animal_insuran")
						}
					>
						<Grid container>
							<TextField
								error={errors && errors.animalInsurance}
								question={animalInsurance}
								name="animalInsurance"
								type="number"
								placeholder="पशु पन्छ बीमा (संख्यामा)"
								value={insurance.animalInsurance}
								isVisible={
									insurance.insuranceLists &&
									insurance.insuranceLists.includes("animal_insuran")
								}
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<Grid container>
						<TextField
							name="insuranceOther"
							type="text"
							placeholder="अन्य बीमा"
							value={insurance.insuranceOther}
							isVisible={
								insurance.insuranceLists &&
								insurance.insuranceLists.includes("other")
							}
							handleChange={this.handleChange}
						/>
					</Grid>
					<BoxBorder
						isVisible={
							insurance.insuranceLists &&
							insurance.insuranceLists.includes("other")
						}
					>
						<Grid container>
							<TextField
								question={insuranceOther}
								name="insuranceOtherCount"
								type="number"
								placeholder="अन्य बीमा (संख्यामा)"
								value={insurance.insuranceOtherCount}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
				</BoxBorder>
			</Grid>
		);
	}
}

const styles = theme => ({
	root: {
		display: "flex"
	},
	formControl: {
		margin: theme.spacing.unit * 3
	},
	group: {
		margin: `${theme.spacing.unit}px 0`,
		display: "flex"
	},
	chips: {
		display: "flex",
		flexWrap: "wrap"
	},
	chip: {
		margin: theme.spacing.unit / 4
	},
	noLabel: {
		marginTop: theme.spacing.unit * 3
	},
	flexColumn: {
		flexDirection: "column"
	}
});

Insurance.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Insurance);
