import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
	birthCertificate,
	birthCertificateQuestion,
	childLaborQuestion,
	childLabour,
	childLabourQuestion,
	childVaccine,
	childVaccineQuestion,
	deliveryFromDoctor,
	deliveryFromDoctorQuestion,
	discrimination,
	discriminationQuestion,
	earlyMarriage,
	earlyMarriageQuestion,
	govHospitalName,
	govHospitalNameQuestion,
	illTreament,
	illTreamentQuestion,
	pregnanctCheck,
	pregnanctCheckQuestion,
	pregnantNutrition,
	pregnantNutritionQuestion,
	pregnantTetanus,
	pregnantTetanusQuestion
} from "../../../../variable/houseSurvey";
import RadioButton from "../../../common/frequent/RadioButton";
import Selection from "../../../common/frequent/Selection";

class WomenChild extends Component {
	handleChange = (name, value) => {
		console.log("WC", name, value);
		this.props.handleChange(`womenChild.${name}`, value);
	};
	render() {
		const { womenChild } = this.props;
		const errors = this.props.errors && this.props.errors.errors;
		return (
			<Grid container>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={birthCertificateQuestion}
						name="birthCertificate"
						radioLists={birthCertificate}
						value={womenChild.birthCertificate}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={childVaccineQuestion}
						name="childVaccine"
						radioLists={childVaccine}
						value={womenChild.childVaccine}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={deliveryFromDoctorQuestion}
						name="deliveryFromDoctor"
						radioLists={deliveryFromDoctor}
						value={womenChild.deliveryFromDoctor}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={pregnantNutritionQuestion}
						name="pregnantNutrition"
						radioLists={pregnantNutrition}
						value={womenChild.pregnantNutrition}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={pregnanctCheckQuestion}
						name="pregnanctCheck"
						radioLists={pregnanctCheck}
						value={womenChild.pregnanctCheck}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={childLaborQuestion}
						name="childLabor"
						radioLists={childLabour}
						value={womenChild.childLabor}
					/>
				</Grid>{" "}
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={childLabourQuestion}
						name="childLabour"
						radioLists={childLabour}
						value={womenChild.childLabour}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={earlyMarriageQuestion}
						name="earlyMarriage"
						radioLists={earlyMarriage}
						value={womenChild.earlyMarriage}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={pregnantTetanusQuestion}
						name="pregnantTetanus"
						radioLists={pregnantTetanus}
						value={womenChild.pregnantTetanus}
					/>
				</Grid>
				<Grid container>
					<Selection
						question={illTreamentQuestion}
						name="illTreament"
						selectList={illTreament}
						value={womenChild.illTreament}
						isVisible
						handleChange={this.handleChange}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible={womenChild.illTreament === "public_hospita"}
						question={govHospitalNameQuestion}
						name="govHospitalName"
						radioLists={govHospitalName}
						value={womenChild.govHospitalName}
						other={womenChild.govHospitalNameOther}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={discriminationQuestion}
						name="discrimination"
						radioLists={discrimination}
						value={womenChild.discrimination}
					/>
				</Grid>
			</Grid>
		);
	}
}
WomenChild.propTypes = {
	classes: PropTypes.object.isRequired
};

export default WomenChild;
