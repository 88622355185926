import {
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Grid
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ExpandIcon from "@material-ui/icons/ExpandMore";
import { Checkbox } from "antd";
import React, { Component } from "react";
import { infastructure, selectedLanguage } from "../../../variable/global";

export default class InfrastructureOption extends Component {
	state = {
		index: -1,
		activeIndex: 0,
		expanded: "infrastructure"
	};

	handleExpansion = panel => (event, expanded) => {
		this.setState({
			expanded: expanded ? panel : false
		});
	};

	handleChange = event => {
		this.props.handleChange(event.target.name, event.target.checked);
	};

	render() {
		const {
			wardSelected,
			infastructureSelected,
			disasterSelected,
			wardCheckAll,
			indeterminateWard,
			boundaryChecked,
			otherCheckbox
		} = this.props;

		return (
			<div>
				<ExpansionPanel
					style={{ padding: 0, backgroundColor: "transparent" }}
					expanded={this.state.expanded === `infrastructure`}
					onChange={this.handleExpansion(`infrastructure`)}
				>
					<ExpansionPanelSummary expandIcon={<ExpandIcon />}>
						{" "}
						<Typography
							variant="body2"
							style={{ fontWeight: 500 }}
							gutterBottom
						>
							आधारभूत संरचना
						</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Grid container direction="column">
							{infastructure.map(each => (
								<Checkbox
									name={each.value}
									onChange={this.handleChange}
									checked={otherCheckbox[each.value]}
								>
									{each[selectedLanguage]}
								</Checkbox>
							))}
						</Grid>
					</ExpansionPanelDetails>
				</ExpansionPanel>
			</div>
		);
	}
}
