import { Grid, MenuItem, Select, Typography } from "@material-ui/core/es";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { getAnalaysisFamilyDetailChartData } from "../../../../actions/analysisAction";
import {
	gaupalikaWard,
	selectedLanguage,
	ward,
	wardAnalysis
} from "../../../../variable/global";
import Can from "../../../casl/Can";
import FullScreenDialog from "../../../common/frequent/dialog/FullScreen";
import MultipleChart from "./charts/ChartD3";
import ChartDoghnot from "./charts/ChartDoghnot";
import BarGraph from "./charts/ChartList";
import ChartPie from "./charts/ChartPie";
import ChartPolar from "./charts/ChartPolar";
import DetailAnalysisTable from "./DetailAnalysisTable";
class DetailAnalysis extends Component {
	constructor(props) {
		super(props);

		const role = parseInt(sessionStorage.getItem("role"));
		const ward = parseInt(sessionStorage.getItem("ward"));

		this.state = {
			wardData: ward,
			multipleFull: false,
			barFull: false,
			pieFull: false,
			doghnutFull: false,
			polarFull: false,
			role,
			ward
		};
	}

	componentDidMount() {
		this.fetchChart();
	}

	onWardClick = event => {
		const wardData = event.target.value;
		this.setState({ wardData }, () => this.fetchChart());
	};

	fetchChart = () => {
		const option = this.props.match.params.option;
		const ward = this.state.wardData;
		// const wardData = ward === 0 ? {} : { wardNumber: ward };
		this.props.getAnalaysisFamilyDetailChartData(ward, option);
	};

	handleClickOpen = name => {
		this.setState({ [name]: true });
	};

	handleClose = name => {
		this.setState({ [name]: false });
	};

	getChartD3Full = () => {
		const { male, female, third } = this.props.chart;

		if (this.props.chart)
			return (
				<FullScreenDialog
					name="multipleFull"
					open={this.state.multipleFull}
					title={`लिङ्ग अनुसार `}
					handleClose={this.handleClose}
				>
					<Grid
						container
						direction="row"
						justify="center"
						alignItems="flex-start"
					>
						{this.props.chart && male && (
							<Grid item xs={9}>
								<MultipleChart
									noTitle
									male={male}
									female={female}
									third={third}
								/>
							</Grid>
						)}
					</Grid>
				</FullScreenDialog>
			);
	};

	getBarChart = chartData => {
		if (this.props.chart)
			return (
				<FullScreenDialog
					name="barFull"
					open={this.state.barFull}
					title="बार ग्राफ"
					handleClose={this.handleClose}
				>
					<Grid
						container
						direction="row"
						justify="center"
						alignItems="flex-start"
					>
						{chartData && (
							<Grid item xs={8}>
								<BarGraph noTitle chartData={chartData} />
							</Grid>
						)}
					</Grid>
				</FullScreenDialog>
			);
	};

	getPieChart = chartData => {
		if (this.props.chart)
			return (
				<FullScreenDialog
					name="pieFull"
					open={this.state.pieFull}
					title="पाइ चार्ट"
					handleClose={this.handleClose}
				>
					<Grid
						container
						direction="row"
						justify="center"
						alignItems="flex-start"
					>
						{chartData && (
							<Grid item xs={8}>
								<ChartPie noTitle chartData={chartData} />
							</Grid>
						)}
					</Grid>
				</FullScreenDialog>
			);
	};

	getDoghnutChart = chartData => {
		if (this.props.chart)
			return (
				<FullScreenDialog
					name="doghnutFull"
					open={this.state.doghnutFull}
					title="डोनट चार्ट"
					handleClose={this.handleClose}
				>
					<Grid
						container
						direction="row"
						justify="center"
						alignItems="flex-start"
					>
						{chartData && (
							<Grid item xs={8}>
								<ChartDoghnot noTitle chartData={chartData} />
							</Grid>
						)}
					</Grid>
				</FullScreenDialog>
			);
	};

	getPolarFull = chartData => {
		if (this.props.chart)
			return (
				<FullScreenDialog
					name="polarFull"
					open={this.state.polarFull}
					title="पोलर चार्ट"
					handleClose={this.handleClose}
				>
					<Grid
						container
						direction="row"
						justify="center"
						alignItems="flex-start"
					>
						{chartData && (
							<Grid item xs={8}>
								<ChartPolar noTitle chartData={chartData} />
							</Grid>
						)}
					</Grid>
				</FullScreenDialog>
			);
	};

	getTitle = (ward, object) => {
		return object[ward][selectedLanguage];
	};

	render() {
		const { loading, classes, chart } = this.props;
		let chartData = {
			label: [],
			data: []
		};
		if (chart && chart.single)
			chartData = {
				label: chart.single.map(each => each.label),
				data: chart.single.map(each => each.value)
			};
		const { wardData } = this.state;
		const chooseWard = { english: "choose ward", nepali: "वडा छान्नुहोस्" };
		return (
			<div>
				{chart && chart.title && (
					<Typography variant="h4" gutterBottom>
						{this.getTitle(wardData, wardAnalysis)} {chart.title}
					</Typography>
				)}
				<Can do="show" on={{ __type: "Home", ward: this.state.ward }}>
					<Grid
						container
						style={{ marginBottom: 10 }}
						spacing={8}
						direction="row"
						justify="center"
						alignItems="flex-start"
					>
						<Grid item>
							<Typography variant="h6">
								{chooseWard[selectedLanguage]}
							</Typography>
						</Grid>

						<Grid item>
							<Select
								className={classes.ward}
								name="ward"
								value={wardData}
								onChange={this.onWardClick}
							>
								<MenuItem value={0}>{gaupalikaWard[selectedLanguage]}</MenuItem>
								{ward.map(each => (
									<MenuItem key={each.key} value={each.key}>
										{each.ward}
									</MenuItem>
								))}
							</Select>
						</Grid>
					</Grid>
				</Can>

				<Grid container>
					{loading ? (
						<Grid
							container
							direction="row"
							justify="center"
							alignItems="center"
						>
							<Grid item>
								<ClipLoader
									sizeUnit={"px"}
									size={84}
									color={"#00ACC1"}
									loading={loading}
								/>
							</Grid>
						</Grid>
					) : (
						<Grid
							container
							direction="column"
							ref={el => (this.componentRef = el)}
						>
							<Grid
								container
								direction="row"
								justify="center"
								alignItems="flex-start"
							>
								{chart && chart.male && (
									<Grid item xs={12} md={9}>
										<MultipleChart
											name="multipleFull"
											isFullScreen={true}
											handleClickOpen={this.handleClickOpen}
											male={chart.male}
											female={chart.female}
											third={chart.third}
										/>
									</Grid>
								)}
							</Grid>
							{this.getChartD3Full(chartData)}
							{this.getBarChart(chartData)}
							{this.getPieChart(chartData)}
							{this.getDoghnutChart(chartData)}
							{this.getPolarFull(chartData)}
							<Grid container spacing={24}>
								{chart && chart.single && (
									<Grid item className="fixWidthofColumn">
										<BarGraph
											name="barFull"
											isFullScreen={true}
											handleClickOpen={this.handleClickOpen}
											chartData={chartData}
											label="बार ग्राफ"
										/>
									</Grid>
								)}
								{chart && chart.single && (
									<Grid item className="fixWidthofColumn">
										<ChartPie
											name="pieFull"
											isFullScreen={true}
											handleClickOpen={this.handleClickOpen}
											chartData={chartData}
											label="पाइ चार्ट"
										/>
									</Grid>
								)}
								{chart && chart.single && (
									<Grid item className="fixWidthofColumn">
										<ChartDoghnot
											name="doghnutFull"
											isFullScreen={true}
											handleClickOpen={this.handleClickOpen}
											chartData={chartData}
											label="डोनट चार्ट"
										/>
									</Grid>
								)}
								{chart && chart.single && (
									<Grid item className="fixWidthofColumn">
										<ChartPolar
											name="polarFull"
											isFullScreen={true}
											handleClickOpen={this.handleClickOpen}
											chartData={chartData}
											label="पोलर चार्ट"
										/>
									</Grid>
								)}
							</Grid>

							<Grid
								id="tobeprint"
								container
								direction="row"
								justify="center"
								alignItems="flex-start"
							>
								{chart && chart.single && (
									<Grid item xs={8}>
										{<DetailAnalysisTable chart={chart} />}
									</Grid>
								)}
							</Grid>
						</Grid>
					)}
				</Grid>
			</div>
		);
	}
}

const styles = theme => ({
	printIcon: {
		// marginRight: theme.spacing.unit * 1,
		color: ""
	},
	fab: {
		position: "fixed",
		right: 60,
		"z-index": 100,
		backgroundColor: "#03a9f4",
		color: "white",
		"&:hover": {
			backgroundColor: "white",
			color: "#03a9f4"
		}
	},
	ward: {
		marginLeft: 15
	}
});

DetailAnalysis.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ analysis: { loading, chart } }) => ({
	loading,
	chart
});
export default withStyles(styles)(
	connect(
		mapStateToProps,
		{ getAnalaysisFamilyDetailChartData }
	)(DetailAnalysis)
);
