import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import getNepalNumber from "../../../../../utils/getNepaliNumber";
import { no, selectedLanguage, yes } from "../../../../../variable/global";
import {
	agricultureTitle,
	area,
	irrigation,
	mal,
	malType,
	production,
	productionShow,
	sales,
	wali,
	waliType
} from "../../../../../variable/houseSurvey";
import {
	QuestionAnswer,
	Title
} from "../../../../common/frequent/QuestionAnswer";

function mapValue(value, obj) {
	return obj[value] ? obj[value] : value;
}

class SearchByHouseNumber extends Component {
	getYesNo = data => (data ? yes[selectedLanguage] : no[selectedLanguage]);
	multimapValue = (name, object) =>
		name && name.map(each => object[each]).join(",");
	render() {
		const agriIncome = [
			"cropIncome",
			"daalIncome",
			"oilIncome",
			"vegitableIncome",
			"fruitIncome",
			"cashCropIncome",
			"flowerIncome"
		];
		const { classes, agriculture } = this.props;
		return (
			<Fragment>
				{agriculture && (
					<Grid item container direction="column">
						<Title title={agricultureTitle} />
						<Grid item container direction="row">
							<Grid item container xs={12}>
								<Grid
									item
									container
									direction="row"
									className={classes.eachQnAns}
								>
									<Grid item xs={8}>
										<QuestionAnswer
											question={agricultureTitle[selectedLanguage]}
											answer={this.getYesNo(
												agriculture.agricultureProductSales
											)}
										/>
									</Grid>
									{agriculture.irrigation && (
										<Grid item xs={4}>
											<QuestionAnswer
												question={irrigation[selectedLanguage]}
												answer={mapValue(agriculture.irrigation, {
													kulo: "कुलो",
													nahar: "नहर",
													boring: "बोरिंग",
													tubewell: "ट्युबवेल",
													lift_badh: "लिफ्टिंङ बाँध",
													pokhari: "पोखरी",
													tap_pipe: "धारा/पाईपबाट",
													rain_water: "वर्षाको पानि"
												})}
											/>
										</Grid>
									)}
								</Grid>
								<Grid
									item
									container
									direction="row"
									className={classes.eachQnAns}
								>
									<Grid item xs={8}>
										<QuestionAnswer
											question={mal[selectedLanguage]}
											answer={this.getYesNo(agriculture.insecticides)}
										/>
									</Grid>
									{agriculture && agriculture.insecticides && (
										<Grid item xs={4}>
											<QuestionAnswer
												question={malType[selectedLanguage]}
												answer={mapValue(agriculture.chemicalUsed, {
													organic: "स्थानीय अर्गानिक",
													chemical: "रासायनिक किट नासक औषधि",
													both_org_chem: "दुवै"
												})}
											/>
										</Grid>
									)}
								</Grid>
								<Grid item container>
									{agriculture.agricultureProductSales && (
										<Table className={classes.table}>
											<TableHead>
												<TableRow>
													<TableCell className={classes.tableHeader}>
														{waliType[selectedLanguage]}
													</TableCell>
													<TableCell
														className={classes.tableHeader}
														align="right"
													>
														{wali[selectedLanguage]}
													</TableCell>
													<TableCell
														className={classes.tableHeader}
														align="right"
													>
														{area[selectedLanguage]}
													</TableCell>
													<TableCell
														className={classes.tableHeader}
														align="right"
													>
														{productionShow[selectedLanguage]}
													</TableCell>
													<TableCell
														className={classes.tableHeader}
														align="right"
													>
														{sales[selectedLanguage]}
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{agriculture.agricultureDetail.map((row, key) => (
													<TableRow key={key}>
														<TableCell
															className={classes.tableColumn}
															component="th"
															scope="row"
														>
															{mapValue(row.agricultureProductionSalesType, {
																crops: "अन्न वालि",
																daal: "दाल वालि",
																oilseeds: "तेलहन उत्पादन",
																vegetable: "तरकारी उत्पादन",
																fruit: "फलफुल",
																cash_crop: "नगदेवाली",
																flower: "फुलखेती"
															})}
														</TableCell>
														<TableCell
															className={classes.tableColumn}
															align="right"
														>
															{mapValue(row.title, {
																crops: "धान",
																jau: "जौ",
																maize: "मकै",
																kodo: "कोदो",
																fapar: "फापर",
																ghau: "गहू",
																black_daal: "कालो दाल",
																rahar: "रहर",
																musuri: "मुसुरी",
																chana: "चना",
																bhatmas: "भट्मास",
																gahat: "गहत",
																mashyam: "मस्याम",
																tori: "तोरी",
																sarshu: "सर्स्यु",
																aalash: "आलस",
																filunge: "फिलुंगे",
																teel: "तिल",
																sunflower: "सूर्यमुखी",
																mango: "आप",
																litchi: "लिची",
																banana: "केरा",
																orange: "सुन्तला",
																junar: "जुनार",
																lemon: "कागती",
																apple: "स्याउ",
																kiwi: "किवी",
																naspati: "नासपाती",
																avogado: "अभोगाड़ो",
																dhasingare: "धसिंगरे",
																alaichi: "अलैची",
																rudraksh: "रुद्राक्ष(दाना)",
																amriso: "अम्रिसो",
																coffee: "कफी",
																tea: "चिया",
																nibuwa: "निबुवा",
																ginger: "अदुवा",
																besaar: "बेसार",
																garlic: "लसुन",
																onion: "प्याज",
																mushroom: "च्याउ",
																sugarcane: "उखु",
																nut: "बदाम",
																chilly: "खुर्सानी",
																potato: "आलु",
																cabbage: "बन्दा",
																cauliflower: "काउली",
																bodi: "बोडी",
																simi: "सिमि",
																tomato: "गोलभेडा",
																cucumber: "काक्रो",
																lauka: "लौका",
																pumpkin: "फर्सी",
																parwal: "पर्वल",
																bittergroud: "करेला",
																ghiraula: "घिरौला",
																chichindo: "चिचिन्दो"
															})}
														</TableCell>
														<TableCell
															className={classes.tableColumn}
															align="right"
														>
															{row &&
																row.area &&
																getNepalNumber(row.area.ropani)}{" "}
															रोपनी &nbsp;
															{row &&
																row.area &&
																getNepalNumber(row.area.aana)}{" "}
															आना &nbsp;
															{row &&
																row.area &&
																getNepalNumber(row.area.paisa)}{" "}
															पैसा
														</TableCell>
														<TableCell
															className={classes.tableColumn}
															align="right"
														>
															{row &&
																row.production &&
																getNepalNumber(row.production.pathi)}{" "}
															पाथी &nbsp;
															{row &&
																row.production &&
																getNepalNumber(row.production.muri)}{" "}
															मुरी
														</TableCell>
														<TableCell
															className={classes.tableColumn}
															align="right"
														>
															{row &&
																row.sale &&
																getNepalNumber(row.sale.pathi)}{" "}
															पाथी &nbsp;
															{row &&
																row.sale &&
																getNepalNumber(row.sale.muri)}{" "}
															मुरी
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									)}
								</Grid>
								<Grid item container>
									{agriculture.agricultureProductSales && (
										<Table className={classes.table}>
											<TableHead>
												<TableRow>
													<TableCell
														className={classes.tableHeader}
														align="right"
													>
														{waliType[selectedLanguage]}
													</TableCell>
													<TableCell
														className={classes.tableHeader}
														align="right"
													>
														{production[selectedLanguage]}
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{agriIncome.map((row, key) => {
													if (agriculture[row]) {
														return (
															<TableRow key={key}>
																<TableCell
																	className={classes.tableColumn}
																	align="right"
																>
																	{mapValue(row, {
																		cropIncome: "अन्न वालि बार्षिक आम्दानी",
																		daalIncome: "दाल वालि बार्षिक आम्दानी",
																		oilIncome: "तेलहन उत्पादन बार्षिक आम्दानी",
																		vegitableIncome:
																			"तरकारी उत्पादन बार्षिक आम्दानी",
																		fruitIncome: "फलफुल बार्षिक आम्दानी",
																		cashCropIncome: "नगदेवाली बार्षिक आम्दानी",
																		flowerIncome: "फुलखेती बार्षिक आम्दानी"
																	})}
																</TableCell>
																<TableCell
																	className={classes.tableColumn}
																	align="right"
																>
																	{agriculture &&
																		agriculture[row] &&
																		getNepalNumber(agriculture[row])}
																</TableCell>
															</TableRow>
														);
													} else return null;
												})}
											</TableBody>
										</Table>
									)}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				)}
			</Fragment>
		);
	}
}

const styles = theme => ({
	root: {
		width: "100%",
		marginTop: theme.spacing.unit * 3,
		overflowX: "auto"
	},
	table: {
		width: "auto"
	},
	container: {
		marginTop: theme.spacing.unit * 3,
		marginBottom: theme.spacing.unit * 2
	},
	eachQnAns: {
		marginTop: theme.spacing.unit * 1
	},
	tableHeader: {
		fontSize: "1rem",
		fontWeight: 600
	},
	tableColumn: {
		fontSize: "0.9rem"
	}
});

SearchByHouseNumber.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SearchByHouseNumber);
