import React from "react";
// import InfiniteScroll from "react-infinite-scroll-component";
import InfiniteScroll from "react-infinite-scroller";
const style = {
	height: 60,
	border: "1px solid green",
	margin: 6,
	padding: 8
};

class Scroll extends React.Component {
	state = {
		items: Array.from({ length: 20 }),
		hasMore: true
	};

	fetchMoreData = () => {
		console.log("requested");
		if (this.state.items.length >= 40) {
			this.setState({ hasMore: false });
			return;
		}
		// a fake async api call like which sends
		// 20 more records in .5 secs
		setTimeout(() => {
			this.setState({
				items: this.state.items.concat(Array.from({ length: 5 }))
			});
		}, 100);
	};

	render() {
		return (
			<div
				style={{ marginBottom: 100 }}
				ref={ref => (this.scrollParentRef = ref)}
			>
				<InfiniteScroll
					// threshold={this.props.total}
					pageStart={0}
					loadMore={this.props.loadMore.bind(this)}
					hasMore={this.props.hasMore}
					loader={this.props.loading}
					useWindow={false}
					getScrollParent={() => this.scrollParentRef}
				>
					{this.props.children}
				</InfiniteScroll>
			</div>
		);
	}
}
export default Scroll;
