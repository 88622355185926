import { Grid } from "@material-ui/core";
import L from "leaflet";
import React, { Component } from "react";
import { LayersControl, Map, TileLayer } from "react-leaflet";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { getHouseByHouseNumber } from "../../../actions/searchAction";
import iconUrl from "../../../image/pin24.png";
import FullScreenDialog from "../../common/frequent/dialog/FullScreen";
import GetHouseDetail from "../search/advancedHouseSearch/houseNumber/GetHouseDetail";
import GeoJsonLayer from "./GeoJSONWithLayer";

const { BaseLayer, Overlay } = LayersControl;

var myIcon = L.icon({
	iconUrl,
	// iconSize: [25, 41],
	iconAnchor: [22, 94],
	popupAnchor: [-10, -90]
});

class Maps extends Component {
	state = {
		location: {
			lat: 27.505,
			lng: 85.09
		},
		haveUserLocation: false,
		zoom: 12,
		baseLayers: {
			"No Basemap": "Empty",
			Streets: "streets",
			Satellite: "satellite",
			Grayscale: "grayscale"
		},
		houseOpen: false,
		house: {},
		houseNumber: null
	};

	MouseOverFeature = (feature, layer, name) => {
		layer.bindPopup(name);
	};

	MouseOutFeature = (feature, layer) => {
		layer.unbindPopup();
	};

	onEachFeature = name => (feature, layer) => {
		return layer.on({
			mouseover: e => this.MouseOverFeature(feature, layer, name),
			mouseout: e => this.MouseOutFeature(feature, layer)
		});
	};

	onEachFeatures = (feature, map) => {
		return map.on({
			mouseover: e =>
				window.setTimeout(
					map.scrollWheelZoom.enable.bind(map.scrollWheelZoom),
					2000
				),
			mouseout: e => map.scrollWheelZoom.disable.bind(map.scrollWheelZoom)
		});
	};

	onHouseNumberClick = houseNumber => {
		this.setState({ houseOpen: true, houseNumber }, () => {
			this.props.getHouseByHouseNumber(houseNumber);
		});
	};
	onHouseNumberClose = () => {
		this.setState({ houseOpen: false, house: {} });
	};

	componentDidUpdate(prevProps) {
		if (prevProps.house !== this.props.house) {
			this.setState({
				house: this.props.house
			});
		}
	}

	// handleMapClick = e => {
	// 	if (e.latlng && e.latlng.lat && e.latlng.lng)
	// 		console.log("lat & lng ", e.latlng.lat + "\n" + e.latlng.lng);
	// };

	render() {
		const { geoJsonDataInfastructure, position, showGeoJSON } = this.props;
		return (
			<div className="map" style={{ width: "100%" }}>
				{this.state.houseOpen && (
					<FullScreenDialog
						name="houseOpen"
						open={this.state.houseOpen}
						title={`House Detail `}
						handleClose={this.onHouseNumberClose}
					>
						<Grid container spacing={24}>
							{this.props.load ? (
								<Grid
									style={{ marginTop: "40vh" }}
									container
									direction="row"
									justify="center"
									alignItems="center"
								>
									<Grid item>
										<ClipLoader
											sizeUnit={"px"}
											size={84}
											color={"#00ACC1"}
											loading={this.props.load}
										/>
									</Grid>
								</Grid>
							) : (
								<Grid
									container
									direction="row"
									justify="center"
									alignItems="flex-start"
								>
									{this.state.house && (
										<Grid item xs={9}>
											<GetHouseDetail
												houseNumber={this.state.houseNumber}
												house={this.state.house}
											/>
										</Grid>
									)}
								</Grid>
							)}
						</Grid>
					</FullScreenDialog>
				)}
				<Map
					id="map"
					className="map"
					center={position}
					zoom={this.state.zoom}
					// zoomControl={false}
					// onEachFeature={this.onEachFeatures}
					// onClick={this.handleMapClick}
					scrollWheelZoom={false}
				>
					<LayersControl position="bottomright">
						<BaseLayer checked name="OpenStreetMap.Mapnik">
							<TileLayer
								attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
								url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
							/>
						</BaseLayer>
						<BaseLayer name="OpenStreetMap.BlackAndWhite">
							<TileLayer
								attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
								url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
							/>
						</BaseLayer>
					</LayersControl>

					{showGeoJSON.length > 0 &&
						showGeoJSON.map(each => {
							console.log("each geojson", each);
							return (
								each.length > 0 &&
								each.map(eachGeoJSON => (
									<GeoJsonLayer
										type={eachGeoJSON.type}
										key={eachGeoJSON.name}
										style={eachGeoJSON.style}
										data={eachGeoJSON.geoJson}
										popupContent={eachGeoJSON.name}
										houseClick={this.onHouseNumberClick}
									/>
								))
							);
						})}
					{/* {geoJsonDataInfastructure.length > 0 &&
						geoJsonDataInfastructure.map(each => {
							console.log("each geojson", each);
							return (
								<GeoJsonLayer
									type={each.type}
									key={each.name}
									style={each.style}
									data={each.geoJson}
									popupContent={each.name}
								/>
							);
						})} */}
					{/* <Overlay name="Marker with popup">
					<Marker position={position} icon={myIcon}>
						<Popup>
							A pretty CSS3 popup. <br /> Easily customizable.
						</Popup>
					</Marker>
					</Overlay> */}
				</Map>
			</div>
		);
	}
}

const mapStateToProps = ({ search: { house, load } }) => ({
	house,
	load
});
export default connect(
	mapStateToProps,
	{ getHouseByHouseNumber }
)(Maps);
// export default Maps;
