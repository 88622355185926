import React, { Component } from "react";
import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography
} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import withStyles from "@material-ui/core/styles/withStyles";
import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "../../common/frequent/print/ReactToPrint";
import getNepaliNumber from "get-nepali-number";
import Card from "../../common/Card/Card.jsx";
import CardBody from "../../common/Card/CardBody.jsx";
import CardHeader from "../../common/Card/CardHeader.jsx";
import GridContainer from "../../common/Grid/GridContainer.jsx";
import GridItem from "../../common/Grid/GridItem.jsx";
import "./style.css";

class reportTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			beforePrint: false,
			component: null
		};
	}

	handleAfterPrint = () => {
		this.setState({ beforePrint: false });
	};

	handleBeforePrint = () => {
		this.setState({ beforePrint: true });
	};

	getContent = () => {
		return this.setState(
			{ beforePrint: true, component: this.houseRef },
			() => {
				return this.state.component;
			}
		);
	};

	render() {
		const { classes, data } = this.props;
		const { male, female, single, third } = data;
		return (
			<div>
				<GridContainer>
					<GridItem xs={12} sm={12} md={12}>
						{/* <Card> */}
						{!this.props.beforePrint && (
							<CardHeader color="primary">
								<Grid
									container
									direction="row"
									justify="space-between"
									alignItems="flex-start"
								>
									<Grid item>
										<Typography variant="h6" className={classes.cardTitleWhite}>
											{data.title}
										</Typography>
									</Grid>
									<Grid item>
										{
											<ReactToPrint
												style={{ marginTop: 200 }}
												onBeforePrint={this.handleBeforePrint}
												onAfterPrint={this.handleAfterPrint}
												trigger={() => (
													<Fab
														variant="extended"
														aria-label="Delete"
														className={classes.fab}
													>
														<PrintIcon className={classes.printIcon} />
													</Fab>
												)}
												data={this.state.component}
												content={this.getContent}
											/>
										}
									</Grid>
								</Grid>
							</CardHeader>
						)}
						<div ref={el => (this.houseRef = el)} className="page">
							<div className="subpage">
								{(this.state.beforePrint || this.props.beforePrint) && (
									<Typography variant="h6" className={classes.title}>
										{data.title}
									</Typography>
								)}
								{/* Table here */}
								<Table>
									<TableHead>
										<TableRow>
											{data.reportTitle.map(header => (
												<TableCell>{header}</TableCell>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{male &&
											male.map((m, id) => (
												<TableRow key={id}>
													<TableCell component="th" scope="row">
														{m.label}
													</TableCell>
													<TableCell align="left">
														{getNepaliNumber(female[id].value)}
													</TableCell>
													<TableCell align="left">
														{getNepaliNumber(m.value)}
													</TableCell>
													<TableCell align="left">
														{getNepaliNumber(third[id].value)}
													</TableCell>
													<TableCell align="left">
														{getNepaliNumber(single[id].value)}
													</TableCell>
												</TableRow>
											))}
										<TableRow>
											<TableCell colspan={1}>जम्मा</TableCell>
											{male &&
												[female, male, third].map(each => (
													<TableCell>
														{getNepaliNumber(
															each.reduce((c, a) => c + a.value, 0)
														)}
													</TableCell>
												))}
											<TableCell>
												{getNepaliNumber(
													single.reduce((c, a) => c + a.value, 0)
												)}
											</TableCell>
										</TableRow>
										<TableRow />
									</TableBody>
								</Table>
							</div>
						</div>
						{/* </Card> */}
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

const styles = theme => ({
	cardCategoryWhite: {
		"&,& a,& a:hover,& a:focus": {
			color: "rgba(255,255,255,.62)",
			margin: "0",
			fontSize: "14px",
			marginTop: "0",
			marginBottom: "0"
		},
		"& a,& a:hover,& a:focus": {
			color: "#FFFFFF"
		}
	},
	cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none",
		"& small": {
			color: "#777",
			fontSize: "65%",
			fontWeight: "400",
			lineHeight: "1"
		}
	},
	root: {
		width: "100%",
		marginTop: theme.spacing.unit * 3
	},
	table: {
		minWidth: 500,
		pageBreakInside: "always",
		pageBreakAfter: "avoid"
	},
	tableWrapper: {
		overflowX: "auto"
	},
	white: {
		color: "#FFF"
	}
	// page: {
	// 	height: "297mm",
	// 	width: "210mm"
	// }
});

export default withStyles(styles)(reportTable);
