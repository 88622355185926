import {
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ExpandIcon from "@material-ui/icons/ExpandMore";
import { Checkbox, Col } from "antd";
import React, { Component } from "react";
import { disaster, selectedLanguage } from "../../../variable/global";

export default class DisasterOption extends Component {
	state = {
		index: -1,
		activeIndex: 0,
		expanded: "disaster"
	};

	handleExpansion = panel => (event, expanded) => {
		this.setState({
			expanded: expanded ? panel : false
		});
	};

	handleChange = event => {
		this.props.handleChange(event.target.name, event.target.checked);
	};

	render() {
		const {
			wardSelected,
			infastructureSelected,
			disasterSelected,
			wardCheckAll,
			indeterminateWard,
			boundaryChecked,
			otherCheckbox
		} = this.props;

		return (
			<div>
				<ExpansionPanel
					style={{ padding: 0, backgroundColor: "transparent" }}
					expanded={this.state.expanded === `disaster`}
					onChange={this.handleExpansion(`disaster`)}
				>
					<ExpansionPanelSummary expandIcon={<ExpandIcon />}>
						{" "}
						<Typography
							variant="body2"
							style={{ fontWeight: 500 }}
							gutterBottom
						>
							प्राकृतिक प्रकोप
						</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Checkbox.Group
							style={{ width: "100%" }}
							onChange={this.props.handleDisasterChange}
							value={disasterSelected}
						>
							{disaster.map(each => (
								<Col key={each.value}>
									<Checkbox value={each.value}>
										{each[selectedLanguage]}
									</Checkbox>
								</Col>
							))}
						</Checkbox.Group>
					</ExpansionPanelDetails>
				</ExpansionPanel>
			</div>
		);
	}
}
