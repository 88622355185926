import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
	cashLoanGiven,
	cashLoanGivenQuestion,
	familyInLocalOrganQuestion,
	familyInvolvementLocalOrgan,
	familyLocalGovInvolve,
	familyLocalGovInvolveQuestion,
	foodAlternate,
	foodAlternateQuestion,
	foodForMonth,
	foodForMonthQuestion,
	loan,
	loanTakenInFamily,
	loanTakenInFamilyQuestion,
	loanTakingReason,
	loanTakingReasonQuestion,
	mainIncomeSource,
	mainIncomeSourceQuestion,
	skillDetail,
	skillTraining,
	skillTrainingQuestion,
	totalExpenditure,
	totalIncome
} from "../../../../variable/houseSurvey";
import BoxBorder from "../../../common/frequent/BoxBorder";
import CheckBox from "../../../common/frequent/CheckBox";
import MultipleSelect from "../../../common/frequent/MultipleSelect";
import RadioButton from "../../../common/frequent/RadioButton";
import Selection from "../../../common/frequent/Selection";
import TextField from "../../../common/frequent/TextField";
class Economics extends Component {
	handleChange = (name, value) => {
		this.props.handleChange(`economics.${name}`, value);
	};
	render() {
		const { economics } = this.props;
		const errors = this.props.errors && this.props.errors.errors;
		return (
			//आर्थिक क्षेत्र् जानकारी
			<Grid container direction="column">
				<Grid container>
					<Selection
						error={errors && errors.mainIncomeSource}
						question={mainIncomeSourceQuestion}
						name="mainIncomeSource"
						selectList={mainIncomeSource}
						value={economics.mainIncomeSource}
						isVisible
						handleChange={this.handleChange}
						other={economics.mainIncomeSourceOther}
					/>
				</Grid>
				<Grid container>
					<TextField
						question={totalIncome}
						name="totalIncome"
						type="number"
						placeholder="वार्षिक आम्दानी"
						value={economics.totalIncome}
						isVisible
						handleChange={this.handleChange}
					/>
				</Grid>
				<Grid container>
					<TextField
						question={totalExpenditure}
						name="totalExpenditure"
						type="number"
						placeholder="वार्षिक खर्च"
						value={economics.totalExpenditure}
						isVisible
						handleChange={this.handleChange}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={foodForMonthQuestion}
						name="foodForMonth"
						radioLists={foodForMonth}
						value={economics.foodForMonth}
						other={economics.foodForMonthOther}
					/>
				</Grid>
				<Grid container>
					<MultipleSelect
						error={errors && errors.foodAlternate}
						question={foodAlternateQuestion}
						name="foodAlternate"
						value={economics.foodAlternate}
						tagLists={foodAlternate}
						isVisible={
							economics.foodForMonth === "3_month" ||
							economics.foodForMonth === "4_6_month" ||
							economics.foodForMonth === "7_9_month"
						}
						handleChange={this.handleChange}
						other={economics.foodAlternateOther}
					/>
				</Grid>
				{/* ends आर्थिक क्षेत्र् जानकारी */}

				{/* ऋण सम्बन्धि */}
				{/* ends ऋण सम्बन्धि */}

				{/* सामाजिक संघ संस्थामा संग्लगनता तथा सिप तालिम */}
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={skillTrainingQuestion}
						name="skillTraining"
						radioLists={skillTraining}
						value={economics.skillTraining}
					/>
				</Grid>
				<BoxBorder
					isVisible={
						economics.skillTraining &&
						economics.skillTraining.includes("skill_yes")
					}
				>
					<Grid container>
						<CheckBox
							checkList={skillDetail}
							value={economics.skillDetail}
							name="skillDetail"
							isVisible
							handleCompChange={this.handleChange}
							other={economics.skillTrainingOther}
						/>
					</Grid>
					<BoxBorder
						isVisible={
							economics.skillDetail &&
							economics.skillDetail.includes("construction")
						}
					>
						<Grid xs={6}>
							<TextField
								name="constructionMale"
								type="number"
								placeholder="निर्माण सम्बन्धि (मिस्त्री/कार्पेन्टर ) पुरुष संख्या"
								value={economics.constructionMale}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid xs={6}>
							<TextField
								name="constructionFemale"
								type="number"
								placeholder="निर्माण सम्बन्धि (मिस्त्री/कार्पेन्टर ) महिला संख्या"
								value={economics.constructionFemale}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							economics.skillDetail &&
							economics.skillDetail.includes("agriculture")
						}
					>
						<Grid xs={6}>
							<TextField
								name="agricultureMale"
								type="number"
								placeholder="कृषि सम्बन्धि पुरुष संख्या"
								value={economics.agricultureMale}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid xs={6}>
							<TextField
								name="agricultureFemale"
								type="number"
								placeholder="कृषि सम्बन्धि महिला संख्या"
								value={economics.agricultureFemale}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							economics.skillDetail &&
							economics.skillDetail.includes("hand_made")
						}
					>
						<Grid xs={6}>
							<TextField
								name="handmadeMale"
								type="number"
								placeholder="कृषि सम्बन्धि पुरुष संख्या"
								value={economics.handmadeMale}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid xs={6}>
							<TextField
								name="handmadeFemale"
								type="number"
								placeholder="कृषि सम्बन्धि महिला संख्या"
								value={economics.handmadeFemale}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							economics.skillDetail &&
							economics.skillDetail.includes("electric")
						}
					>
						<Grid xs={6}>
							<TextField
								name="electricMale"
								type="number"
								placeholder="सूचना प्रविधि ,इलेक्ट्रोनिकस,इलेक्त्रोनिकल पुरुष संख्या"
								value={economics.electricMale}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid xs={6}>
							<TextField
								name="electricFemale"
								type="number"
								placeholder="सूचना प्रविधि ,इलेक्ट्रोनिकस,इलेक्त्रोनिकल महिला संख्या"
								value={economics.electricFemale}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							economics.skillDetail && economics.skillDetail.includes("fashion")
						}
					>
						<Grid xs={6}>
							<TextField
								name="fashionMale"
								type="number"
								placeholder="सिलाई ,बुनाइ ,बुटिक ,पर्लोर, सृंगार पुरुष संख्या"
								value={economics.fashionMale}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid xs={6}>
							<TextField
								name="fashionFemale"
								type="number"
								placeholder="सिलाई ,बुनाइ ,बुटिक ,पर्लोर, सृंगार महिला संख्या"
								value={economics.fashionFemale}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							economics.skillDetail && economics.skillDetail.includes("health")
						}
					>
						<Grid xs={6}>
							<TextField
								name="healthMale"
								type="number"
								placeholder="जनस्वास्थ सम्बन्धि पुरुष संख्या"
								value={economics.healthMale}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid xs={6}>
							<TextField
								name="healthFemale"
								type="number"
								placeholder="जनस्वास्थ सम्बन्धि महिला संख्या"
								value={economics.healthFemale}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							economics.skillDetail &&
							economics.skillDetail.includes("animal_health")
						}
					>
						<Grid xs={6}>
							<TextField
								name="animalhealthMale"
								type="number"
								placeholder="पशु स्वस्थ सम्बन्धि पुरुष संख्या"
								value={economics.animalhealthMale}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid xs={6}>
							<TextField
								name="animalhealthFemale"
								type="number"
								placeholder="पशु स्वस्थ सम्बन्धि महिला संख्या"
								value={economics.animalhealthFemale}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							economics.skillDetail && economics.skillDetail.includes("tourism")
						}
					>
						<Grid xs={6}>
							<TextField
								name="tourismMale"
								type="number"
								placeholder="पर्यटन , टुर गाइड, ट्राभेल र सत्कार सम्बन्धि पुरुष संख्या"
								value={economics.tourismMale}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid xs={6}>
							<TextField
								name="tourismFemale"
								type="number"
								placeholder="पर्यटन , टुर गाइड, ट्राभेल र सत्कार सम्बन्धि महिला संख्या"
								value={economics.tourismFemale}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							economics.skillDetail && economics.skillDetail.includes("art")
						}
					>
						<Grid xs={6}>
							<TextField
								name="skillMale"
								type="number"
								placeholder="कला सम्बन्धि पुरुष संख्या"
								value={economics.skillMale}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid xs={6}>
							<TextField
								name="skillFemale"
								type="number"
								placeholder="कला सम्बन्धि महिला संख्या"
								value={economics.skillFemale}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							economics.skillDetail && economics.skillDetail.includes("other")
						}
					>
						<Grid xs={6}>
							<TextField
								name="skillTrainingOther"
								placeholder="अन्य भए उल्लेख गर्नुहोस"
								value={economics.skillTrainingOther}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid xs={6}>
							<TextField
								name="skillMaleOther"
								type="number"
								placeholder={`${
									economics.skillTrainingOther
								} सम्बन्धि पुरुष संख्या`}
								value={economics.skillMaleOther}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid xs={6}>
							<TextField
								name="skillFemaleOther"
								type="number"
								placeholder={`${
									economics.skillTrainingOther
								} सम्बन्धि महिला संख्या`}
								value={economics.skillFemaleOther}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
				</BoxBorder>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={familyInLocalOrganQuestion}
						name="familyInvolvementLocalOrgan"
						radioLists={familyInvolvementLocalOrgan}
						value={economics.familyInvolvementLocalOrgan}
					/>
				</Grid>
				<Grid container>
					<MultipleSelect
						question={familyLocalGovInvolveQuestion}
						name="familyLocalGovInvolve"
						value={economics.familyLocalGovInvolve}
						tagLists={familyLocalGovInvolve}
						isVisible={economics.familyInvolvementLocalOrgan === "local_yes"}
						handleChange={this.handleChange}
						other={economics.familyLocalGovInvolveOther}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={loanTakenInFamilyQuestion}
						name="loanTakenInFamily"
						radioLists={loanTakenInFamily}
						value={economics.loanTakenInFamily}
					/>
				</Grid>
				<BoxBorder
					isVisible={
						economics.loanTakenInFamily &&
						economics.loanTakenInFamily.includes("loan_yes")
					}
				>
					<Grid container>
						<CheckBox
							checkList={loan}
							value={economics.loan}
							name="loan"
							isVisible={economics.loanTakenInFamily === "loan_yes"}
							handleCompChange={this.handleChange}
							other={economics.loanOther}
						/>
					</Grid>
					<Grid xs={6}>
						<TextField
							name="bankrate"
							type="number"
							placeholder="बैंकको व्याज दर कति प्रतिशत (%) छ ?"
							value={economics.bankrate}
							isVisible={economics.loan && economics.loan.includes("loan_bank")}
							handleChange={this.handleChange}
						/>
					</Grid>
					<Grid xs={6}>
						<TextField
							name="personrate"
							type="number"
							placeholder="व्यक्तिको व्याज दर कति प्रतिशत (%) छ ?"
							value={economics.personrate}
							isVisible={
								economics.loan && economics.loan.includes("loan_person")
							}
							handleChange={this.handleChange}
						/>
					</Grid>
					<Grid xs={6}>
						<TextField
							name="sahakarirate"
							type="number"
							placeholder="सहकारीको व्याज दर कति प्रतिशत (%) छ ?"
							value={economics.sahakarirate}
							isVisible={
								economics.loan && economics.loan.includes("loan_sahakari")
							}
							handleChange={this.handleChange}
						/>
					</Grid>
					<Grid xs={6}>
						<TextField
							name="laghubittrate"
							type="number"
							placeholder="लघुबित्तको व्याज दर कति प्रतिशत (%) छ ?"
							value={economics.laghubittrate}
							isVisible={
								economics.loan && economics.loan.includes("loan_laghubitt")
							}
							handleChange={this.handleChange}
						/>
					</Grid>
					<Grid xs={6}>
						<TextField
							name="farmerrate"
							type="number"
							placeholder="कृषकको व्याज दर कति प्रतिशत (%) छ ?"
							value={economics.farmerrate}
							isVisible={
								economics.loan && economics.loan.includes("loan_farmer")
							}
							handleChange={this.handleChange}
						/>
					</Grid>
					<Grid xs={6}>
						<TextField
							name="femalerate"
							type="number"
							placeholder="महिला समुहको व्याज दर कति प्रतिशत (%) छ ?"
							value={economics.femalerate}
							isVisible={
								economics.loan && economics.loan.includes("loan_women")
							}
							handleChange={this.handleChange}
						/>
					</Grid>
					<Grid xs={6}>
						<TextField
							name="loanOther"
							type="text"
							placeholder="अन्य भए उल्लेख गर्नुहोस"
							value={economics.loanOther}
							isVisible={economics.loan && economics.loan.includes("other")}
							handleChange={this.handleChange}
						/>
					</Grid>
					<Grid xs={6}>
						<TextField
							name="otherrate"
							type="number"
							placeholder="अन्य व्याज दर कति प्रतिशत (%) छ ?"
							value={economics.otherrate}
							isVisible={economics.loan && economics.loan.includes("other")}
							handleChange={this.handleChange}
						/>
					</Grid>
				</BoxBorder>
				<Grid container>
					<MultipleSelect
						question={loanTakingReasonQuestion}
						name="loanTakingReason"
						value={economics.loanTakingReason}
						tagLists={loanTakingReason}
						isVisible={economics.loanTakenInFamily === "loan_yes"}
						handleChange={this.handleChange}
						other={economics.loanTakingReasonOther}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={cashLoanGivenQuestion}
						name="cashLoanGiven"
						radioLists={cashLoanGiven}
						value={economics.cashLoanGiven}
					/>
				</Grid>
				<Grid xs={6}>
					<TextField
						name="cashLoanRate"
						type="number"
						placeholder="छ भने कति प्रतिशत (%) व्याज दरमा दिनु भएको छ ?"
						value={economics.cashLoanRate}
						isVisible={economics.cashLoanGiven == "cash_loan_yes"}
						handleChange={this.handleChange}
					/>
				</Grid>
			</Grid>
		);
	}
}
const styles = theme => ({
	root: {
		display: "flex"
	},
	formControl: {
		margin: theme.spacing.unit * 3
	},
	group: {
		margin: `${theme.spacing.unit}px 0`,
		display: "flex"
	},
	chips: {
		display: "flex",
		flexWrap: "wrap"
	},
	chip: {
		margin: theme.spacing.unit / 4
	},
	noLabel: {
		marginTop: theme.spacing.unit * 3
	},
	flexColumn: {
		flexDirection: "column"
	}
});
Economics.propTypes = {
	classes: PropTypes.object.isRequired
};
export default withStyles(styles)(Economics);
