import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import getNepalNumber from "../../../../../utils/getNepaliNumber";
import { no, selectedLanguage, yes } from "../../../../../variable/global";
import {
	anyBankAccount,
	cookingSource,
	electricSource,
	familyTitle,
	hasinsur,
	homeAge,
	insuranceDetail,
	numberofBankAccount,
	saltUsed,
	toiletType,
	waterSource
} from "../../../../../variable/houseSurvey";
import {
	QuestionAnswer,
	Title
} from "../../../../common/frequent/QuestionAnswer";

function mapValue(value, obj, defaultValue) {
	return obj[value] || defaultValue;
}

class HouseIntro extends Component {
	getYesNo = data => (data ? yes[selectedLanguage] : no[selectedLanguage]);

	render() {
		const { classes, secondaryHouseDetails } = this.props;
		return (
			<Grid item container direction="column" className={classes.container}>
				<Title title={familyTitle} />
				<Grid item container direction="column">
					{/* first */}
					<Grid
						item
						container
						direction="row"
						className={classes.eachQnAns}
						spacing={24}
					>
						<Grid item xs={4}>
							<QuestionAnswer
								question={waterSource[selectedLanguage]}
								answer={mapValue(secondaryHouseDetails.waterSource, {
									pipe_in_home: "पाइप धारा घरमा",
									public_pipe: "पाइप धारा सार्वजनिक",
									boring: "बोरिंग",
									hand_tube_pump: "ट्युबवेल/हेन्डपम्प",
									well: "ढाकिएको इनार / कुवा",
									mulko_pani: "नढाकिएको इनार / कुवा",
									river_water: "नदि खोला",
									rain_water: "आकासे पानी",
									jar: "जारको पानी",
									tanker: "ट्यांकरको पानी"
								})}
							/>
						</Grid>
						<Grid item xs={4}>
							<QuestionAnswer
								question={electricSource[selectedLanguage]}
								answer={mapValue(secondaryHouseDetails.electricSource, {
									electric: "बिधुतलाईन",
									kerosene: "मट्टीतेल/टुकी",
									solar: "सोलार",
									bio_gas: "वायो ग्यास",
									torch_battery: "टर्च लाईट /ब्याट्री"
								})}
							/>
						</Grid>
						<Grid item xs={4}>
							<QuestionAnswer
								question={cookingSource[selectedLanguage]}
								answer={mapValue(secondaryHouseDetails.cookingSource, {
									wood: "दाउरा/काठ(साधरण चुलो )",
									sudharieko_chu: "दाउरा/काठ(सुधारिएको चुलो )",
									kerosene: "मट्टीतेल/स्टोभ",
									lpg: "एल.पी.जी.ग्यास",
									bio: "वायो ग्यास",
									electric: "विधुत",
									solar: "सौर्य उर्जा",
									dung: "गुइठा"
								})}
							/>
						</Grid>
					</Grid>
					{/* end */}

					<Grid
						item
						container
						direction="row"
						className={classes.eachQnAns}
						spacing={24}
					>
						<Grid item xs={4}>
							<QuestionAnswer
								question={toiletType[selectedLanguage]}
								answer={mapValue(secondaryHouseDetails.toilet, {
									no_toilet: "शौचालय नभएको",
									public_tank: "फ्लस भएको(सार्वजनिक ढल)",
									private_tank: "फ्लस भएको(सेफ्टी ट्याङ्क)",
									ordinary_tank: "साधारण खाडलमा ढाकेको",
									shared_toilet: "सामुदायिक चर्पी",
									water_seal: "वाटर सिल",
									toilet_not_use: "शौचालय भएको तर प्रयोग नगरेको"
								})}
							/>
						</Grid>
						<Grid item xs={4}>
							<QuestionAnswer
								question={anyBankAccount[selectedLanguage]}
								answer={this.getYesNo(secondaryHouseDetails.bankAccount)}
							/>
						</Grid>
						{secondaryHouseDetails.numberofBankAccount && (
							<Grid item xs={4}>
								<QuestionAnswer
									question={numberofBankAccount[selectedLanguage]}
									answer={
										secondaryHouseDetails.numberofBankAccount &&
										getNepalNumber(secondaryHouseDetails.numberofBankAccount)
									}
								/>
							</Grid>
						)}

						<Grid item xs={4}>
							<QuestionAnswer
								question={homeAge[selectedLanguage]}
								answer={
									secondaryHouseDetails.houseAge &&
									`${getNepalNumber(secondaryHouseDetails.houseAge)} बर्ष`
								}
							/>
						</Grid>
					</Grid>

					<Grid
						item
						container
						direction="row"
						className={classes.eachQnAns}
						spacing={24}
					>
						<Grid item xs={4}>
							<QuestionAnswer
								question={saltUsed[selectedLanguage]}
								answer={
									secondaryHouseDetails.saltUsed &&
									mapValue(secondaryHouseDetails.saltUsed, {
										iodine_salt: "आयोडिन्युक्त नून",
										dhiko_salt: "ढिको नून",
										open_salt: "खुल्ला नून"
									})
								}
							/>
						</Grid>

						<Grid item xs={4}>
							<QuestionAnswer
								question={hasinsur[selectedLanguage]}
								answer={this.getYesNo(secondaryHouseDetails.hasInsurance)}
							/>
						</Grid>
					</Grid>

					{secondaryHouseDetails.hasInsurance && (
						<Grid
							item
							container
							direction="column"
							className={classes.eachQnAns}
						>
							<QuestionAnswer question={insuranceDetail[selectedLanguage]} />
							<Grid item xs={6}>
								<Table className={classes.table}>
									<TableHead>
										<TableRow>
											{secondaryHouseDetails.insurance &&
												secondaryHouseDetails.insurance.map(each => (
													<TableCell
														align="left"
														className={classes.tableHeader}
													>
														{mapValue(
															each.name,
															{
																life_insurance: "जिबन बिमा",
																health_insuran: "स्वास्थ्य बिमा",
																non_life_insur: "नीर जीवन बिमा",
																animal_insuran: "पशु पन्छी बिमा"
															},
															"अन्य बीमा"
														)}
													</TableCell>
												))}
										</TableRow>
									</TableHead>

									<TableBody>
										<TableRow>
											{secondaryHouseDetails.insurance &&
												secondaryHouseDetails.insurance.map(each => (
													<TableCell
														align="left"
														className={classes.tableColumn}
													>
														{each.count && getNepalNumber(each.count)}
													</TableCell>
												))}
										</TableRow>
									</TableBody>
								</Table>
							</Grid>
						</Grid>
					)}
				</Grid>
			</Grid>
		);
	}
}

const styles = theme => ({
	container: {
		marginTop: theme.spacing.unit * 3,
		marginBottom: theme.spacing.unit * 2
	},
	table: {
		width: "auto"
	},
	tableHeader: {
		fontSize: "1rem",
		fontWeight: 600
	},
	tableColumn: {
		fontSize: "0.9rem"
	}
});

HouseIntro.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(HouseIntro);
