import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import analysisReducer from "./analysisReducer";
import searchReducer from "./searchReducer";
import houseSurvey from "./houseSurveyReducer";
import instituteReducer from "./instituteReducer";
import languageReducer from "./languageReducer";
export default combineReducers({
	auth: authReducer,
	errors: errorReducer,
	analysis: analysisReducer,
	search: searchReducer,
	houseSurvey: houseSurvey,
	instituteReducer: instituteReducer,
	language: languageReducer
});
