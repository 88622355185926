import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import getNepalNumber from "../../../../../utils/getNepaliNumber";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { no, selectedLanguage, yes } from "../../../../../variable/global";
import {
	QuestionAnswer,
	Title
} from "../../../../common/frequent/QuestionAnswer";
import {
	skills,
	skillType,
	skillMaleNo,
	skillFemaleNo,
	skillYesNo,
} from "../../../../../variable/houseSurvey";

function mapValue(value, obj) {
	return obj[value];
}

class SearchByHouseNumber extends Component {
	getYesNo = data => (data ? yes[selectedLanguage] : no[selectedLanguage]);
	render() {
		const { classes, skill } = this.props;
		return (
			<Grid item container direction="column" className={classes.container}>
				<Title title={skills} />

				<Grid item container>
					{skill.hasSkills ? (
						<Table className={classes.table}>
							<TableHead>
								<TableRow>
									<TableCell>{skillType[selectedLanguage]}</TableCell>
									<TableCell align="right">
										{skillMaleNo[selectedLanguage]}
									</TableCell>
									<TableCell align="right">
										{skillFemaleNo[selectedLanguage]}
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{skill.detail.map((row, key) => (
									<TableRow key={key}>
										<TableCell scope="row">
											{mapValue(row.title, {
												Construction: "निर्माण सम्बन्धि (मिस्त्री/कार्पेन्टर )",
												Agriculture:
													"कृषि सम्बन्धि (जे.टि, जे.टि.ए र खद प्रसोधन )",
												"Hand Made": "बास तथा छाला बाट निर्मित हस्त सामग्री",
												Electric:
													"सूचना प्रविधि ,इलेक्ट्रोनिकस,इलेक्त्रोनिकल(कम्पुटर /मोबाइल/रेडियो/घडी मर्मत)",
												Fashion: "सिलाई ,बुनाइ ,बुटिक ,पर्लोर, सृंगार",
												Health: "जनस्वास्थ सम्बन्धि",
												"Animal Health": "पशु स्वस्थ सम्बन्धि",
												Tourism: "पर्यटन , टुर गाइड, ट्राभेल र सत्कार सम्बन्धि",
												Art: "कला सम्बन्धि"
											})}
										</TableCell>
										<TableCell align="right">
											{getNepalNumber(row.maleCount)}
										</TableCell>
										<TableCell align="right">
											{getNepalNumber(row.femaleCount)}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					) : (
						<QuestionAnswer
							question={skillYesNo[selectedLanguage]}
							answer={this.getYesNo(skill.hasSkills)}
						/>
					)}
				</Grid>
			</Grid>
		);
	}
}

const styles = theme => ({
	table: {
		width: "auto"
	},
	container: {
		marginTop: theme.spacing.unit * 3,
		marginBottom: theme.spacing.unit * 2
	}
});

SearchByHouseNumber.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SearchByHouseNumber);
