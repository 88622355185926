import { Grid, IconButton } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import {
	Button,
	Divider,
	Form,
	Icon,
	Input,
	message,
	Modal,
	Radio,
	Select,
	Typography,
	Upload
} from "antd";
import Axios from "axios";
import moment from "moment";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addSchoolSurvey } from "../../../actions/instituteAction";
import isEmpty from "../../../utils/validation/is-empty";
import { no, selectedLanguage, ward, yes } from "../../../variable/global";
import {
	add,
	address,
	appointAs,
	area,
	benchCapacity,
	childplayground,
	compound,
	designation,
	email,
	establish,
	femaleCount,
	femalestudent,
	gharTitle,
	ground,
	hasdrinking,
	hastoilet,
	instituteType,
	kachiCount,
	kachiRoomCount,
	latitude,
	longitude,
	maleCount,
	malestudent,
	name,
	pakkiCount,
	pakkiRoomCount,
	phone,
	photo,
	roomTitle,
	schoolClass,
	schoolLevel,
	schoollevel,
	schooltype,
	specName,
	specTitle,
	specValue,
	staff,
	staffCount,
	student,
	toilet,
	wardQuestion,
	website
} from "../../../variable/institute";

const { Option } = Select;
const RadioGroup = Radio.Group;

class School extends Component {
	random = (low, high) => {
		return Math.random() * (high - low) + low;
	};
	state = {
		// image upload state
		uniqueIdentifier:
			this.props.match.params.category +
			"_" +
			this.random(10000, 99999) +
			this.random(1000, 9999),
		previewVisible: false,
		previewImage: "",
		fileList: {
			schoolImage: []
		},
		loaded: 0,
		// other
		name: "",
		ward: "",
		benchCapacity: "",
		establishedAt: "",
		phone: "",
		website: "",
		email: "",
		address: "",
		schoolType: "",
		schoolLevel: "",
		latitude: "",
		buildingKacchiCount: "",
		bulidingPakkiCount: "",
		roomKacchiCount: "",
		roomPakkiCount: "",
		longitude: "",
		childPlayGround: "",
		groundArea: "",
		hasToilet: "",
		maleToilet: "",
		femaleToilet: "",
		staffToilet: "",
		compoundCondition: "",
		hasDrinkingWater: "",
		maxStaffIndex: 1,
		maxStudentIndex: 1,
		maxSpecIndex: 1,
		staff: [],
		students: [],
		spec: [],
		isView: this.props.location.view
	};

	// image functions uplocad
	handleCancel = () => this.setState({ previewVisible: false });

	handlePreview = file => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true
		});
	};

	// imageUpload
	handleChangeFile = (name, value, imageName) => {
		//name ma house and value ma list of filelist
		console.log("CF", value);
		this.setState(
			prevState => ({
				...prevState,
				fileList: {
					...prevState.fileList,
					[name]: {
						image: value.fileList,
						fileName: prevState.uniqueIdentifier + "_" + imageName + ".jpg"
					}
				}
			}),
			() => {
				if (value.fileList.length > 0) this.handleUpload(name, imageName);
				if (value.fileList.length === 0) this.removeUpload(name, imageName);
			}
		);
	};

	// upload image
	handleUpload = (name, imageName) => {
		const fileList = this.state.fileList[name];
		const data = new FormData();
		const imageObject = fileList.image[0].originFileObj;

		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";
		data.append("file", imageObject, fileName);

		Axios.post("/api/imageUpload/upload", data, {
			onUploadProgress: ProgressEvent => {
				this.setState({
					loaded: Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100)
				});
			}
		})
			.then(res => {
				// then print response status
				message.success("upload successfully.");
				console.log(res.statusText);
			})
			.catch(err => message.error("upload failed."));
	};

	// remove uploaded image
	removeUpload = imageName => {
		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";

		Axios.get(`/api/imageUpload/delete/${fileName}`)
			.then(res => {
				// then print response status
				message.success("deleted successfully.");
				console.log(res.statusText);
			})
			.catch(err => message.error("upload failed."));
	};

	handleDateChange = (name, value) => {
		this.setState({ [name]: value });
	};
	componentDidMount() {
		let schoolId = this.props.match.params.id;
		Axios.get(`/api/school/fetch/${schoolId}`)
			.then(res => {
				this.setState(
					{
						name: res.data.name,
						address: res.data.address,
						category: res.data.category,
						establishedAt: moment(res.data.establishedAt).format("YYYY-MM-DD"),
						website: res.data.website,
						ward: res.data.ward,
						schoolType: res.data.schoolType,
						spec: res.data.specification,
						maxSpecIndex:
							res.data.specification && res.data.specification.length,
						maxStudentIndex: res.data.student && res.data.student.length,
						maxStaffIndex: res.data.staff && res.data.staff.length,
						schoolLevel: res.data.schoolLevel,
						latitude: res.data.geo && res.data.geo.lat,
						longitude: res.data.geo && res.data.geo.long,
						benchCapacity: res.data.benchCapacity,
						students: res.data.student,
						staff: res.data.staff,
						hasDrinkingWater: res.data.hasDrinkingWater,
						phone: res.data.phone,
						buildingKacchiCount:
							res.data.building && res.data.building.kacchiCount,
						bulidingPakkiCount:
							res.data.building && res.data.building.pakkiCount,
						roomKacchiCount: res.data.room && res.data.room.kacchiCount,
						roomPakkiCount: res.data.room && res.data.room.pakkiCount,
						childPlayGround: res.data.ground && res.data.ground.childPlayGround,
						groundArea: res.data.ground && res.data.ground.area,
						compoundCondition: res.data.compound && res.data.compound.condition,
						hasToilet: res.data.toilet && res.data.toilet.hasToilet,
						maleToilet: res.data.toilet && res.data.toilet.male,
						femaleToilet: res.data.toilet && res.data.toilet.female,
						staffToilet: res.data.toilet && res.data.toilet.staff,
						email: res.data.email,
						// image
						fileList: {
							school: {
								fileName: res.data.uniqueIdentifier + "_school.jpg",
								image: [
									{
										uid: "-1",
										name: `${res.data.uniqueIdentifier}_school.jpg`,
										status: "done",
										url: `/api/imageUpload/image/${
											res.data.uniqueIdentifier
										}_school.jpg`
									}
								]
							}
						}
					},
					() => console.log("in cmds", this.state.name)
				);
			})
			.catch(err => {});
	}

	onSubmit = event => {
		event.preventDefault();
		console.log("area", this.state.groundArea);
		const newSchool = {
			name: this.state.name,
			category: this.props.category,
			uniqueIdentifier: this.state.uniqueIdentifier,
			ward: this.state.ward,
			benchCapacity: this.state.benchCapacity,
			establishedAt: this.state.establishedAt,
			website: this.state.website,
			phone: this.state.phone,
			address: this.state.address,
			schoolType: this.state.schoolType,
			schoolLevel: this.state.schoolLevel,
			email: this.state.email,
			long: this.state.longitude,
			lat: this.state.latitude,
			buildingKacchiCount: this.state.buildingKacchiCount,
			bulidingPakkiCount: this.state.bulidingPakkiCount,
			roomKacchiCount: this.state.roomKacchiCount,
			roomPakkiCount: this.state.roomPakkiCount,
			childPlayGround: this.state.childPlayGround,
			groundArea: this.state.groundArea,
			hasToilet: this.state.hasToilet,
			maleToilet: this.state.maleToilet,
			femaleToilet: this.state.femaleToilet,
			staffToilet: this.state.staffToilet,
			compoundCondition: this.state.compoundCondition,
			hasDrinkingWater: this.state.hasDrinkingWater,
			staff: this.state.staff,
			student: this.state.students,
			specification: this.state.spec
		};
		if (this.props.isUpdate) {
			newSchool.id = this.props.match.params.id;
			this.props.addSchoolSurvey(newSchool, this.props.history);
		} else this.props.addSchoolSurvey(newSchool, this.props.history);
		this.props.history.push(
			`/survey-by-institution/show/${this.props.match.params.category}`
		);
	};

	handleChange = event => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	handleSelectChange = (name, value) => {
		this.setState({
			[name]: value
		});
	};

	handleInputChange = name => event => {
		this.setState({
			[name]: event.target.value
		});
	};

	addStaff = () => {
		this.setState(prevState => ({
			maxStaffIndex: prevState.maxStaffIndex + 1
		}));
	};

	addSpec = () => {
		this.setState(prevState => ({
			maxSpecIndex: prevState.maxSpecIndex + 1
		}));
	};

	addStudent = () => {
		this.setState(prevState => ({
			maxStudentIndex: prevState.maxStudentIndex + 1
		}));
	};

	handleNameSpec = index => event => {
		// arr.splice(2, 1, "Lene");
		// event.persist();

		const name = event.target.value;
		this.setState(prevState => {
			const spec = [...prevState.spec];

			const data = {};
			data.name = name;
			data.value = isEmpty(spec[index]) ? "" : spec[index].value;
			spec.splice(index, 1, data);
			return { spec };
		});
	};

	handleValueSpec = index => event => {
		const value = event.target.value;
		this.setState(prevState => {
			const spec = [...prevState.spec];

			const data = {};
			data.value = value;
			data.name = isEmpty(spec[index]) ? "" : spec[index].name;
			spec.splice(index, 1, data);
			return { spec };
		});
	};

	handleNameStaff = index => event => {
		const name = event.target.value;
		this.setState(prevState => {
			const staff = [...prevState.staff];

			const data = {};
			data.name = name;
			data.address = isEmpty(staff[index]) ? "" : staff[index].address;
			data.phone = isEmpty(staff[index]) ? "" : staff[index].phone;
			data.designation = isEmpty(staff[index]) ? "" : staff[index].designation;
			data.appointAs = isEmpty(staff[index]) ? "" : staff[index].appointAs;
			staff.splice(index, 1, data);
			return { staff };
		});
	};

	handleAddressStaff = index => event => {
		const address = event.target.value;
		this.setState(prevState => {
			const staff = [...prevState.staff];

			const data = {};
			data.address = address;
			data.name = isEmpty(staff[index]) ? "" : staff[index].name;
			data.phone = isEmpty(staff[index]) ? "" : staff[index].phone;
			data.designation = isEmpty(staff[index]) ? "" : staff[index].designation;
			data.appointAs = isEmpty(staff[index]) ? "" : staff[index].appointAs;
			staff.splice(index, 1, data);
			return { staff };
		});
	};

	handlePhoneStaff = index => event => {
		const phone = event.target.value;
		this.setState(prevState => {
			const staff = [...prevState.staff];

			const data = {};
			data.phone = phone;
			data.name = isEmpty(staff[index]) ? "" : staff[index].name;
			data.address = isEmpty(staff[index]) ? "" : staff[index].address;
			data.designation = isEmpty(staff[index]) ? "" : staff[index].designation;
			data.appointAs = isEmpty(staff[index]) ? "" : staff[index].appointAs;
			staff.splice(index, 1, data);
			return { staff };
		});
	};

	handleDesignationStaff = index => event => {
		const designation = event.target.value;
		this.setState(prevState => {
			const staff = [...prevState.staff];

			const data = {};
			data.designation = designation;
			data.name = isEmpty(staff[index]) ? "" : staff[index].name;
			data.address = isEmpty(staff[index]) ? "" : staff[index].address;
			data.phone = isEmpty(staff[index]) ? "" : staff[index].phone;
			data.appointAs = isEmpty(staff[index]) ? "" : staff[index].appointAs;
			staff.splice(index, 1, data);
			return { staff };
		});
	};

	handleAppointStaff = index => event => {
		const appointAs = event.target.value;
		this.setState(prevState => {
			const staff = [...prevState.staff];

			const data = {};
			data.appointAs = appointAs;
			data.name = isEmpty(staff[index]) ? "" : staff[index].name;
			data.address = isEmpty(staff[index]) ? "" : staff[index].address;
			data.phone = isEmpty(staff[index]) ? "" : staff[index].phone;
			data.designation = isEmpty(staff[index]) ? "" : staff[index].designation;
			staff.splice(index, 1, data);
			return { staff };
		});
	};

	handleClassStudent = index => event => {
		const student = event.target.value;
		this.setState(prevState => {
			const students = [...prevState.students];

			const data = {};
			data.class = student;
			data.male = isEmpty(students[index]) ? "" : students[index].male;
			data.female = isEmpty(students[index]) ? "" : students[index].female;
			students.splice(index, 1, data);
			return { students };
		});
	};

	handleMaleStudent = index => event => {
		const male = event.target.value;
		this.setState(prevState => {
			const students = [...prevState.students];

			const data = {};
			data.male = male;
			data.class = isEmpty(students[index]) ? "" : students[index].class;
			data.female = isEmpty(students[index]) ? "" : students[index].female;
			students.splice(index, 1, data);
			return { students };
		});
	};

	handleFemaleStudent = index => event => {
		const female = event.target.value;
		this.setState(prevState => {
			const students = [...prevState.students];

			const data = {};
			data.female = female;
			data.class = isEmpty(students[index]) ? "" : students[index].class;
			data.male = isEmpty(students[index]) ? "" : students[index].male;
			students.splice(index, 1, data);
			return { students };
		});
	};

	removeStudent = index => () => {
		this.setState(prevState => {
			const students = [...prevState.students];
			students.splice(index, 1);
			return { students, maxStudentIndex: prevState.maxStudentIndex - 1 };
		});
	};

	removeStaff = index => () => {
		this.setState(prevState => {
			const staff = [...prevState.staff];
			staff.splice(index, 1);
			return { staff, maxStaffIndex: prevState.maxStaffIndex - 1 };
		});
	};

	removeSpec = index => () => {
		this.setState(prevState => {
			const spec = [...prevState.spec];
			spec.splice(index, 1);
			return { spec, maxSpecIndex: prevState.maxSpecIndex - 1 };
		});
	};

	getStudentUI = () => {
		const formItemLayoutWithOutLabel = {
			wrapperCol: {
				xs: { span: 24, offset: 0 },
				sm: { span: 20, offset: 4 }
			}
		};
		const { maxStudentIndex, students } = this.state;
		return (
			<Grid
				container
				item
				xs={12}
				direction="column"
				justify="flex-start"
				alignItems="flex-start"
			>
				{[...Array(maxStudentIndex).keys()].map(each => {
					return (
						<Grid
							container
							direction="row"
							justify="flex-start"
							alignItems="flex-start"
						>
							<Grid xs={6}>
								<Form.Item label={schoolClass[selectedLanguage]}>
									<Input
										name="class"
										type="number"
										value={students && students[each] && students[each].class}
										onChange={this.handleClassStudent(each)}
										margin="normal"
										variant="outlined"
										disabled={this.state.isView}
									/>
								</Form.Item>
							</Grid>
							<Grid xs={6}>
								<Form.Item label={malestudent[selectedLanguage]}>
									<Input
										name="male"
										type="number"
										value={students && students[each] && students[each].male}
										onChange={this.handleMaleStudent(each)}
										margin="normal"
										variant="outlined"
										disabled={this.state.isView}
									/>
								</Form.Item>
							</Grid>

							<Grid xs={6}>
								<Form.Item label={femalestudent[selectedLanguage]}>
									<Input
										name="female"
										type="number"
										value={students && students[each] && students[each].female}
										onChange={this.handleFemaleStudent(each)}
										margin="normal"
										variant="outlined"
										disabled={this.state.isView}
									/>
								</Form.Item>
							</Grid>

							{each > 0 && (
								<Grid item>
									<IconButton
										onClick={this.removeStudent(each)}
										disabled={this.state.isView}
									>
										<RemoveIcon style={{ color: "red" }} />
									</IconButton>
								</Grid>
							)}
						</Grid>
					);
				})}
				<Form.Item {...formItemLayoutWithOutLabel}>
					<Button
						type="dashed"
						onClick={this.addStudent}
						disabled={this.state.isView}
					>
						<Icon type="plus" />
						{add[selectedLanguage]}
					</Button>
				</Form.Item>
			</Grid>
		);
	};

	getSpecUI = () => {
		const formItemLayoutWithOutLabel = {
			wrapperCol: {
				xs: { span: 24, offset: 0 },
				sm: { span: 20, offset: 4 }
			}
		};
		const { maxSpecIndex, spec } = this.state;
		return (
			<Grid
				container
				item
				xs={12}
				direction="column"
				justify="flex-start"
				alignItems="flex-start"
			>
				{[...Array(maxSpecIndex).keys()].map(each => {
					return (
						<Grid
							container
							direction="row"
							justify="flex-start"
							alignItems="flex-start"
						>
							<Grid xs={5}>
								<Form.Item label={specName[selectedLanguage]}>
									<Input
										name="name"
										type="text"
										value={spec && spec[each] && spec[each].name}
										onChange={this.handleNameSpec(each)}
										margin="normal"
										variant="outlined"
										disabled={this.state.isView}
									/>
								</Form.Item>
							</Grid>
							<Grid xs={5}>
								<Form.Item label={specValue[selectedLanguage]}>
									<Input
										name="value"
										type="text"
										value={spec && spec[each] && spec[each].value}
										onChange={this.handleValueSpec(each)}
										margin="normal"
										variant="outlined"
										disabled={this.state.isView}
									/>
								</Form.Item>
							</Grid>

							{each > 0 && (
								<Grid item>
									<IconButton
										onClick={this.removeSpec(each)}
										disabled={this.state.isView}
									>
										<RemoveIcon style={{ color: "red" }} />
									</IconButton>
								</Grid>
							)}
						</Grid>
					);
				})}
				<Form.Item {...formItemLayoutWithOutLabel}>
					<Button
						type="dashed"
						onClick={this.addSpec}
						disabled={this.state.isView}
					>
						<Icon type="plus" />
						{add[selectedLanguage]}
					</Button>
				</Form.Item>
			</Grid>
		);
	};

	getStaffUI = () => {
		const formItemLayoutWithOutLabel = {
			wrapperCol: {
				xs: { span: 24, offset: 0 },
				sm: { span: 20, offset: 4 }
			}
		};
		const { maxStaffIndex, staff } = this.state;
		return (
			<Grid
				container
				item
				xs={12}
				direction="column"
				justify="flex-start"
				alignItems="flex-start"
			>
				{[...Array(maxStaffIndex).keys()].map(each => {
					return (
						<Grid
							container
							direction="row"
							justify="flex-start"
							alignItems="flex-start"
						>
							<Grid xs={6}>
								<Form.Item label={name[selectedLanguage]}>
									<Input
										name="name"
										type="text"
										value={staff && staff[each] && staff[each].name}
										onChange={this.handleNameStaff(each)}
										margin="normal"
										variant="outlined"
										disabled={this.state.isView}
									/>
								</Form.Item>
							</Grid>
							<Grid xs={6}>
								<Form.Item label={phone[selectedLanguage]}>
									<Input
										name="phone"
										type="number"
										value={staff && staff[each] && staff[each].phone}
										onChange={this.handlePhoneStaff(each)}
										margin="normal"
										variant="outlined"
										disabled={this.state.isView}
									/>
								</Form.Item>
							</Grid>

							<Grid xs={6}>
								<Form.Item label={address[selectedLanguage]}>
									<Input
										name="address"
										type="text"
										value={staff && staff[each] && staff[each].address}
										onChange={this.handleAddressStaff(each)}
										margin="normal"
										variant="outlined"
										disabled={this.state.isView}
									/>
								</Form.Item>
							</Grid>

							<Grid xs={6}>
								<Form.Item label={designation[selectedLanguage]}>
									<Input
										name="designation"
										type="text"
										value={staff && staff[each] && staff[each].designation}
										onChange={this.handleDesignationStaff(each)}
										margin="normal"
										variant="outlined"
										disabled={this.state.isView}
									/>
								</Form.Item>
							</Grid>

							<Grid xs={6}>
								<Form.Item label={appointAs[selectedLanguage]}>
									<Input
										name="appointAs"
										type="text"
										value={staff && staff[each] && staff[each].appointAs}
										onChange={this.handleAppointStaff(each)}
										margin="normal"
										variant="outlined"
										disabled={this.state.isView}
									/>
								</Form.Item>
							</Grid>

							{each > 0 && (
								<Grid item>
									<IconButton
										onClick={this.removeStaff(each)}
										disabled={this.state.isView}
									>
										<RemoveIcon style={{ color: "red" }} />
									</IconButton>
								</Grid>
							)}
						</Grid>
					);
				})}
				<Form.Item {...formItemLayoutWithOutLabel}>
					<Button
						type="dashed"
						onClick={this.addStaff}
						disabled={this.state.isView}
					>
						<Icon type="plus" />
						{add[selectedLanguage]}
					</Button>
				</Form.Item>
			</Grid>
		);
	};

	render() {
		const { classes, title } = this.props;
		const { previewVisible, previewImage } = this.state;
		const fileList = this.state.fileList;
		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload</div>
			</div>
		);
		console.log("name", this.state.name);
		return (
			<Paper className={classes.root} elevation={1}>
				<Typography variant="h5">{title}</Typography>
				<Form {...formItemLayout} onSubmit={this.onSubmit}>
					<Grid xs={6}>
						<Form.Item label={name[selectedLanguage]}>
							<Input
								onChange={this.handleInputChange("name")}
								value={this.state.name}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item label={wardQuestion[selectedLanguage]}>
							<Select
								placeholder="------------"
								onChange={val => this.handleSelectChange("ward", val)}
								value={this.state.ward}
								disabled={this.state.isView}
							>
								{ward.map(woda => (
									<Option value={woda.value}>{woda[selectedLanguage]}</Option>
								))}
							</Select>
						</Form.Item>
					</Grid>
					<Grid xs={6}>
						<Form.Item label={photo[selectedLanguage]}>
							<Fragment>
								{!this.state.isView ? (
									<Upload
										name="file"
										// beforeUpload={this.props.beforeUpload}
										multiple={false}
										// action="/api/imageUpload/upload"
										accept="image/*"
										listType="picture-card"
										fileList={
											fileList.schoolImage && fileList.schoolImage.image
										}
										onPreview={this.handlePreview}
										onChange={fileList =>
											this.handleChangeFile("schoolImage", fileList, "school")
										}
										disabled={this.state.isView}
									>
										{fileList &&
										fileList.schoolImage &&
										fileList.schoolImage.image &&
										fileList.schoolImage.image.length === 1
											? null
											: uploadButton}
									</Upload>
								) : (
									<Modal
										visible={previewVisible}
										footer={null}
										onCancel={this.handleCancel}
									>
										<img
											alt="example"
											style={{ width: "100%" }}
											src={previewImage}
										/>
									</Modal>
								)}
							</Fragment>
						</Form.Item>
					</Grid>
					<Divider orientation="left">{gharTitle[selectedLanguage]}</Divider>
					<Grid xs={6}>
						<Form.Item label={kachiCount[selectedLanguage]}>
							<Input
								type="number"
								onChange={this.handleInputChange("buildingKacchiCount")}
								value={this.state.buildingKacchiCount}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item label={pakkiCount[selectedLanguage]}>
							<Input
								type="number"
								onChange={this.handleInputChange("bulidingPakkiCount")}
								value={this.state.bulidingPakkiCount}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>

					<Divider orientation="left">{roomTitle[selectedLanguage]}</Divider>
					<Grid xs={6}>
						<Form.Item label={kachiRoomCount[selectedLanguage]}>
							<Input
								type="number"
								onChange={this.handleInputChange("roomKacchiCount")}
								value={this.state.roomKacchiCount}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item label={pakkiRoomCount[selectedLanguage]}>
							<Input
								type="number"
								onChange={this.handleInputChange("roomPakkiCount")}
								value={this.state.roomPakkiCount}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>

					<Divider orientation="left">{ground[selectedLanguage]}</Divider>
					<Grid xs={6}>
						<Form.Item label={area[selectedLanguage]}>
							<Input
								type="number"
								addonAfter="square meter"
								onChange={this.handleInputChange("groundArea")}
								value={this.state.groundArea}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item label={childplayground[selectedLanguage]}>
							<RadioGroup
								name="childPlayGround"
								onChange={this.handleChange}
								value={this.state.childPlayGround ? "yes" : "no"}
							>
								<Radio value="yes" disabled={this.state.isView}>
									{yes[selectedLanguage]}
								</Radio>
								<Radio value="no" disabled={this.state.isView}>
									{no[selectedLanguage]}
								</Radio>
							</RadioGroup>
						</Form.Item>
					</Grid>

					<Divider orientation="left">{toilet[selectedLanguage]}</Divider>

					<Grid xs={6}>
						<Form.Item label={hastoilet[selectedLanguage]}>
							<RadioGroup
								name="hasToilet"
								onChange={this.handleChange}
								value={this.state.hasToilet ? "yes" : "no"}
							>
								<Radio value="yes" disabled={this.state.isView}>
									{yes[selectedLanguage]}
								</Radio>
								<Radio value="no" disabled={this.state.isView}>
									{no[selectedLanguage]}
								</Radio>
							</RadioGroup>
						</Form.Item>
					</Grid>
					<Grid xs={6}>
						<Form.Item label={maleCount[selectedLanguage]}>
							<Input
								type="number"
								onChange={this.handleInputChange("maleToilet")}
								value={this.state.maleToilet}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>
					<Grid xs={6}>
						<Form.Item label={femaleCount[selectedLanguage]}>
							<Input
								type="number"
								onChange={this.handleInputChange("femaleToilet")}
								value={this.state.femaleToilet}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>
					<Grid xs={6}>
						<Form.Item label={staffCount[selectedLanguage]}>
							<Input
								type="number"
								onChange={this.handleInputChange("staffToilet")}
								value={this.state.staffToilet}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>
					<Divider />
					<Grid xs={6}>
						<Form.Item label={compound[selectedLanguage]}>
							<Input
								onChange={this.handleInputChange("compoundCondition")}
								value={this.state.compoundCondition}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>
					<Grid xs={6}>
						<Form.Item label={benchCapacity[selectedLanguage]}>
							<Input
								type="number"
								onChange={this.handleInputChange("benchCapacity")}
								value={this.state.benchCapacity}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>
					<Grid xs={6}>
						<Form.Item label={hasdrinking[selectedLanguage]}>
							<RadioGroup
								name="hasDrinkingWater"
								onChange={this.handleChange}
								value={this.state.hasDrinkingWater ? "yes" : "no"}
							>
								<Radio value="yes" disabled={this.state.isView}>
									{yes[selectedLanguage]}
								</Radio>
								<Radio value="no" disabled={this.state.isView}>
									{no[selectedLanguage]}
								</Radio>
							</RadioGroup>
						</Form.Item>
					</Grid>
					<Divider orientation="left">{staff[selectedLanguage]}</Divider>
					{this.getStaffUI()}
					<Divider orientation="left">{student[selectedLanguage]}</Divider>
					{this.getStudentUI()}
					<Divider orientation="left">{specTitle[selectedLanguage]}</Divider>
					{this.getSpecUI()}
					<Divider />
					<Grid xs={6}>
						<Form.Item label={establish[selectedLanguage]}>
							<Input
								type="date"
								name="establishedAt"
								onChange={this.handleInputChange("establishedAt")}
								value={this.state.establishedAt}
								disabled={this.state.isView}
							/>
							{/* <DatePicker
                name="establishedAt"
                onChange={(d, s) => this.handleDateChange("establishedAt", s)}
                value={this.state.establishedAt}
              /> */}
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item label={address[selectedLanguage]}>
							<Input
								onChange={this.handleInputChange("address")}
								value={this.state.address}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item label={phone[selectedLanguage]}>
							<Input
								type="number"
								onChange={this.handleInputChange("phone")}
								value={this.state.phone}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item label={website[selectedLanguage]}>
							<Input
								onChange={this.handleInputChange("website")}
								value={this.state.website}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item label={email[selectedLanguage]}>
							<Input
								type="email"
								onChange={this.handleInputChange("email")}
								value={this.state.email}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item label={latitude[selectedLanguage]}>
							<Input
								type="number"
								onChange={this.handleInputChange("latitude")}
								value={this.state.latitude}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>
					<Grid xs={6}>
						<Form.Item label={longitude[selectedLanguage]}>
							<Input
								type="number"
								onChange={this.handleInputChange("longitude")}
								value={this.state.longitude}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item label={schooltype[selectedLanguage]}>
							<Select
								placeholder="------------"
								onChange={val => this.handleSelectChange("schoolType", val)}
								value={this.state.schoolType}
								disabled={this.state.isView}
							>
								{instituteType.map(type => (
									<Option value={type.value}>{type[selectedLanguage]}</Option>
								))}
							</Select>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item label={schoollevel[selectedLanguage]}>
							<Select
								placeholder="------------"
								onChange={val => this.handleSelectChange("schoolLevel", val)}
								value={this.state.schoolLevel}
								disabled={this.state.isView}
							>
								{schoolLevel.map(level => (
									<Option value={level.value}>{level[selectedLanguage]}</Option>
								))}
							</Select>
						</Form.Item>
					</Grid>

					<Form.Item>
						<Button
							type="primary"
							htmlType="submit"
							disabled={this.state.isView}
						>
							{this.props.isUpdate ? "update" : add[selectedLanguage]}
						</Button>
					</Form.Item>
				</Form>
			</Paper>
		);
	}
}

const styles = theme => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2
	}
});

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 }
	}
};

const mapStateToProps = state => ({});

export default withStyles(styles)(
	connect(
		mapStateToProps,
		{ addSchoolSurvey }
	)(withRouter(School))
);
